@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.light {
  background: linear-gradient(to bottom right, #f0fdf4, #cfe0fc);
  color: #333333;
}

.light .bg-background {
  background-color: transparent;

  .light .text-text {
    color: #333333;
  }

  .dark {
    background-color: #1a202c;
    color: #f3f4f6;
  }

  .dark .bg-background {
    background-color: #1a202c;
  }

  .dark .text-text {
    color: #f3f4f6;
  }
}
